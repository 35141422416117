<template>
  <div  class="minWidth1000">
    <custom-actions show-back>
      <el-form class="mt30" :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="form.menuName"/>
        </el-form-item>
        <el-form-item label="叶子节点" prop="isLeaf">
          <el-radio-group v-model="form.isLeaf">
            <el-radio label="1" border>是</el-radio>
            <el-radio label="0" border>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.isLeaf === '1'" label="父级菜单" prop="parentMenuId">
          <!-- <el-select v-model="form.parentMenuId" ref="select">
            <el-option :value="form.parentMenuId" :label="parentMenuName" style="height:200px;overflow: auto;background-color:#fff">
              <el-tree ref="tree" :render-after-expand="false" node-key="deptId" @node-click="handleNodeClick" :default-expanded-keys="[form.parentMenuId]" :highlight-current="true" :props="{ label: 'menuName', children: 'childNode' }" :data="menus"></el-tree>
            </el-option>
          </el-select> -->
          <el-cascader
            v-model="form.parentMenuId"
            :show-all-levels="false"
            :options="menus"
            clearable
            :props="{ emitPath: false, value: 'menuId', label: 'menuName', children: 'childNode', checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="图标名称" prop="iconPath">
          <el-input v-model="form.iconPath"/>
        </el-form-item>
        <el-form-item label="菜单地址" prop="">
          <el-input v-model="form.url"/>
        </el-form-item>
        <el-form-item label="菜单排序">
          <el-input v-model="form.orderNum"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="1" border>启用</el-radio>
            <el-radio label="2" border>不启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="按钮权限" prop="button">
          <div class="quanxian">
            <el-input v-model="currentButton.butCode" placeholder="请输入code"/>
            <el-input v-model="currentButton.butName" placeholder="请输入名称"/>
            <el-button type="primary" @click="addButton">添加</el-button>
          </div>
          <div class="tags">
            <el-tag
              v-for="(item, index) of form.buttonList"
              :key="index"
              type="primary"
              closable
              @close="delButton(index)">
              {{item.butCode}} {{item.butName}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="$router.back()">取消</el-button>
        </el-form-item>
      </el-form>
    </custom-actions>
  </div>
</template>

<script>
export default {
  name: 'MenuAdd',
  data () {
    return {
      form: {
        menuName: '',
        url: '',
        parentMenuId: '',
        iconPath: '',
        status: '1',
        orderNum: '',
        isLeaf: '1'
      },
      parentMenuName: '',
      rules: {
        menuName: [{ required: true, message: '请输入菜单名称' }],
        isLeaf: [{ required: true, message: '请输入菜单名称' }],
        iconPath: [{ required: true, message: '请输入图标名称' }],
        parentMenuId: [{ required: true, message: '请选择父级菜单' }]
      },
      menus: [],
      currentButton: {
        butCode: '',
        butName: ''
      }
    }
  },
  computed: {
    menuId () {
      return this.$route.query.id
    }
  },
  created () {
    this.loadMenus().then(() => {
      if (this.menuId) {
        this.loadMenuDetails()
      }
    })
  },
  methods: {
    loadMenus () {
      return this.$axios.get(this.$apis.system.selectSysMenuByList).then(res => {
        this.menus = res
        this.formatItems(this.menus, null)
      })
    },
    formatItems (items, parentMenuName) {
      items.forEach(item => {
        item.parentMenuName = parentMenuName
        if (item.childNode && item.childNode.length) {
          this.formatItems(item.childNode, item.menuName)
        }
      })
    },
    handleNodeClick (node) {
      if (this.form.menuId === node.menuId) {
        this.$message.error('不能选择自身作为上级')
      } else {
        this.form.parentMenuId = node.menuId
        this.parentMenuName = node.menuName
        this.$refs.select.blur()
      }
    },
    loadMenuDetails () {
      this.$axios.get(this.$apis.system.selectSysMenuByMenuId, {
        params: {
          menuId: this.menuId
        }
      }).then(res => {
        this.form = res
        this.getParentMenuName(this.menus)
      })
    },
    getParentMenuName (items) {
      items.forEach(item => {
        if (item.menuId === this.form.parentMenuId) {
          this.parentMenuName = item.menuName
        } else {
          if (item.childNode && item.childNode.length) {
            this.getParentMenuName(item.childNode)
          }
        }
      })
    },
    addButton () {
      const button = JSON.parse(JSON.stringify(this.currentButton))
      this.form.buttonList.push(button)
      this.currentButton.butCode = ''
      this.currentButton.butName = ''
    },
    delButton (index) {
      this.form.buttonList.splice(index, 1)
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.menuId ? this.$apis.system.updateSysMenu : this.$apis.system.insertSysMenu
          this.$axios.post(url, this.form).then(res => {
            this.$store.dispatch('loadUserMenus')
            this.$router.back()
          })
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
  .el-input {
    width: 400px;
  }

  .quanxian {
    display: flex;

    .el-input {
      width: 120px;
      margin-right: 10px;
    }
  }

  .tags {
    margin-top: 10px;

    .el-tag {
      margin-right: 10px;
    }
  }
</style>
